$( document ).ready(function() {
    $('body').addClass('removeffect');
});

$(window).scroll(function() {
  if($('body').hasClass('page-template-default') || $('body').hasClass('single-product') || $('body').hasClass('woocommerce-shop') || $('body').hasClass('tax-product_cat')){

  }else{

    if ($(document).scrollTop() > 80) {
        $('.header_wrapper').addClass('scrolled');
        $('#landing').addClass('scrolled');
    }
    else{
      $('.header_wrapper').removeClass('scrolled');
      $('#landing').removeClass('scrolled');
    }
  }

});

$( document ).ready(function() {
    if($('body').hasClass('page-template-default')  || $('body').hasClass('single-product') || $('body').hasClass('woocommerce-shop') || $('body').hasClass('tax-product_cat') ){
        $('.header_wrapper').addClass('scrolled');
    }
});


/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})


$(".cat-parent>a").on('click', function() {
  if($(this).parent().hasClass('clicked')){
    $(this).parent().removeClass('clicked');
    $(this).parent().find('.children').slideUp();
  }else{
    $(this).parent().addClass('clicked');
    $(this).parent().find('.children').slideDown();
  }
  return false;
})

$(".filterb").on('click', function() {
  if($(".shop_sidebar").hasClass('active')){
  }else{
    jQuery("body").css('overflow-y','hidden');
    $(".shop_sidebar").addClass('active');

  }
})

$(".exits").on('click', function() {
  if($(".shop_sidebar").hasClass('active')){
    $("body").css('overflow-y','scroll');
    $(".shop_sidebar").removeClass('active');
  }
})

/*Open Menu*/
$(".menu_open button").on('click', function() {
    if($(this).hasClass('is-active')){
        $("body").css('overflow-y','scroll');
        $(this).removeClass('is-active');
        $('.headermenu').removeClass('is-active');
        $('.header_wrapper').removeClass('openmenu');
        $('.menu li').removeClass('aos-animate');
        if($('li.menu-item-has-children').hasClass('cliked')){
          $('li.menu-item-has-children').removeClass('cliked');
        }
      }
      else{
        $(this).addClass('is-active');
        jQuery("body").css('overflow-y','hidden');
        $('.headermenu').addClass('is-active');
        $('.header_wrapper').addClass('openmenu');
        setTimeout(
        function()
        {
          $( ".menu li" ).each(function( index ) {
                        // $(this).addClass('aos-animate');
                        // $(this).addClass('aos-init');

          });
      }, 1000);
    }
});

$(document).ready(function($) {
  if ($(window).width()<=768) {
    var headerheight=$('.header_wrapper').height();
    $('#banner').css('margin-top',headerheight);
  }
});


document.addEventListener("scroll", (event) => {
  const height = $(window).scrollTop();
  var offsetHeight = $('.site-footer').offset().top - $('.site-footer').heigh();
  if(height >= (offsetHeight)){
    $(".filterb").css('opacity','0');
  }else{
    $(".filterb").css('opacity','1');
  }
});


$("li.menu-item-has-children>a").on('click', function() {
  if($(window).width()<= 1280){
    if($(this).parent().hasClass('cliked')){
      return true;
    }else{
      $(this).parent().addClass('cliked');
      return false;
    }
  }else{
    return true;
  }
})

$( document ).ready(function() {
  if($('ul').hasClass('sub-menu') ){
      $('ul.sub-menu>li.menu-item-has-children>ul').addClass('subb-menu');
      $('ul.sub-menu>li.menu-item-has-children>ul').removeClass('sub-menu');
  }
});


/*Gallery*/
import Swiper, { Navigation } from 'swiper';
const gallery_swi = new Swiper(".gallery_swipe", {
  slidesPerView: "1",
  centeredSlides: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});

const banner_swipe = new Swiper(".banner_swipe", {
  slidesPerView: "1",
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
    speed: 1000,
  },
  centeredSlides: false,
  spaceBetween: 0,
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  }
});

const categories_swipe = new Swiper(".categories_swipe", {
  slidesPerView: "3",
  centeredSlides: false,
  spaceBetween: 50,
  // loop:true,
  navigation: {
    nextEl: '.cat-swiper-button-prev',
    prevEl: '.cat-swiper-button-next',
  },
  breakpoints: {
  0: {
    slidesPerView: "1",
    // centeredSlides: false,
  },
  650: {
    slidesPerView: "2",
    // centeredSlides: false,
  },
  1440: {
    slidesPerView: "3",
    // centeredSlides: false,
  }
}
});

/* Featured home swipper */
if($(".home #featured").length){

    new Swiper(".featured_swipe", {
        slidesPerView: 1.5,
        grabCursor: true,
        navigation: {
            nextEl: ".arrows .next",
            prevEl: ".arrows .prev",
        },
        spaceBetween: 15,
        breakpoints: {
            1440: {
                slidesPerView: 3.5,
                spaceBetween: 70
            },
            991: {
                slidesPerView: 2.5,
                spaceBetween: 40
              },
            0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
              }
          }
    });

    // fix last item height in featured slider
    var featSlHeight = $('#featured .featured_products .swiper-wrapper').outerHeight();
    $('#featured .featured_products .swiper-slide.last').css('min-height', featSlHeight);
};


$( document ).ready(function() {
  var newimg_src;
  $( ".product" ).each(function( index ) {
  newimg_src=$(this).attr('data-imagehover');
  if(newimg_src){
    $(this).find('.woocommerce-LoopProduct-link').addClass('secim');
    $(this).find('.woocommerce-LoopProduct-link').append('<img class="sec" src='+newimg_src+' alt="Sorellina">');
  }
  
  });
});